// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-about-page-tsx": () => import("./../../../src/templates/about-page.tsx" /* webpackChunkName: "component---src-templates-about-page-tsx" */),
  "component---src-templates-basic-page-2-tsx": () => import("./../../../src/templates/basic-page-2.tsx" /* webpackChunkName: "component---src-templates-basic-page-2-tsx" */),
  "component---src-templates-basic-page-tsx": () => import("./../../../src/templates/basic-page.tsx" /* webpackChunkName: "component---src-templates-basic-page-tsx" */),
  "component---src-templates-contact-page-tsx": () => import("./../../../src/templates/contact-page.tsx" /* webpackChunkName: "component---src-templates-contact-page-tsx" */),
  "component---src-templates-faq-page-tsx": () => import("./../../../src/templates/faq-page.tsx" /* webpackChunkName: "component---src-templates-faq-page-tsx" */),
  "component---src-templates-foster-adopt-tsx": () => import("./../../../src/templates/foster-adopt.tsx" /* webpackChunkName: "component---src-templates-foster-adopt-tsx" */),
  "component---src-templates-index-page-tsx": () => import("./../../../src/templates/index-page.tsx" /* webpackChunkName: "component---src-templates-index-page-tsx" */),
  "component---src-templates-news-events-tsx": () => import("./../../../src/templates/news-events.tsx" /* webpackChunkName: "component---src-templates-news-events-tsx" */),
  "component---src-templates-news-page-tsx": () => import("./../../../src/templates/news-page.tsx" /* webpackChunkName: "component---src-templates-news-page-tsx" */),
  "component---src-templates-repeater-modal-tsx": () => import("./../../../src/templates/repeater-modal.tsx" /* webpackChunkName: "component---src-templates-repeater-modal-tsx" */),
  "component---src-templates-system-page-tsx": () => import("./../../../src/templates/system-page.tsx" /* webpackChunkName: "component---src-templates-system-page-tsx" */)
}

